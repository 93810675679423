import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const SecurityAndAuthenticationSettings = () => {
  return (
    <Layout>
      <Seo
        title="How to Access Security and Authentication Settings"
        description={`Secure your Synkli account with our guide on managing passwords, setting up Two-Factor Authentication (2FA), and ensuring account security.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`security-and-authentication-settings`} />
    </Layout>
  )
}

export default SecurityAndAuthenticationSettings
